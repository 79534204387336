import React from "react";
import * as Sentry from "@sentry/react";

interface SentryErrorProviderProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  eventId: string | null;
}

class SentryErrorProvider extends React.Component<
  SentryErrorProviderProps,
  ErrorBoundaryState
> {
  constructor(props: SentryErrorProviderProps) {
    super(props);
    this.state = {
      hasError: false,
      eventId: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope((scope) => {
      // 에러 정보를 객체로 구조화
      const errorDetails = {
        name: error.name,
        message: error.message,
        stack: error.stack,
        componentStack: errorInfo.componentStack,
        // 추가 컨텍스트 정보
        timestamp: new Date().toISOString(),
        location: window.location.href,
      };

      scope.setExtras(errorDetails);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  handleRetry = () => {
    this.setState({ hasError: false });
  };

  handleReportFeedback = () => {
    if (this.state.eventId) {
      Sentry.showReportDialog({ eventId: this.state.eventId });
    }
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
          <div className="p-8 bg-white rounded-lg shadow-md max-w-md w-full">
            <h1 className="text-2xl font-bold text-red-600 mb-4">
              예기치 않은 오류가 발생했습니다
            </h1>
            <p className="text-gray-600 mb-6">
              죄송합니다. 문제가 발생했습니다. 이미 개발팀에 알림이 전송되었으며
              곧 해결하도록 하겠습니다.
            </p>
            <div className="space-x-4">
              <button
                onClick={this.handleRetry}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                다시 시도
              </button>
              <button
                onClick={this.handleReportFeedback}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
              >
                피드백 보내기
              </button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default SentryErrorProvider;
