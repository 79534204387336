import type { FallbackProps } from "react-error-boundary";

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  // HTTP 상태 코드를 확인
  const getStatusCode = (
    error: Error & { response?: { status: number }; status?: number }
  ) => {
    // error가 undefined인 경우 처리
    if (!error) return "UNKNOWN_ERROR";

    // error.response가 있는 경우 (Axios 에러)
    if (error.response?.status) {
      return error.response.status;
    }

    // error.status가 있는 경우 (fetch 에러)
    if (error.status) {
      return error.status;
    }

    // 네트워크 에러의 경우
    if (error.message?.includes("Network Error")) {
      return "NETWORK_ERROR";
    }

    return "UNKNOWN_ERROR";
  };

  const statusCode = getStatusCode(error);

  const handleNavigate = (path: string) => {
    window.location.href = path;
  };

  const renderErrorContent = () => {
    switch (statusCode) {
      case 400:
        return (
          <div className="text-center p-8">
            <h2 className="text-2xl font-bold text-red-600">
              잘못된 요청입니다
            </h2>
            <p className="mt-2 text-gray-600">요청을 확인해주세요.</p>
          </div>
        );
      case 401:
        return (
          <div className="text-center p-8">
            <h2 className="text-2xl font-bold text-red-600">
              인증이 필요합니다
            </h2>
            <p className="mt-2 text-gray-600">다시 로그인해주세요.</p>
            <button
              onClick={() => handleNavigate("/login")}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              로그인하기
            </button>
          </div>
        );
      case 403:
        return (
          <div className="text-center p-8">
            <h2 className="text-2xl font-bold text-red-600">
              접근 권한이 없습니다
            </h2>
            <p className="mt-2 text-gray-600">필요한 권한을 확인해주세요.</p>
          </div>
        );
      case 404:
        return (
          <div className="text-center p-8">
            <h2 className="text-2xl font-bold text-red-600">
              페이지를 찾을 수 없습니다
            </h2>
            <p className="mt-2 text-gray-600">
              요청하신 페이지가 존재하지 않습니다.
            </p>
            <button
              onClick={() => handleNavigate("/")}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              홈으로 가기
            </button>
          </div>
        );
      case 500:
        return (
          <div className="text-center p-8">
            <h2 className="text-2xl font-bold text-red-600">서버 오류</h2>
            <p className="mt-2 text-gray-600">잠시 후 다시 시도해주세요.</p>
          </div>
        );
      case "NETWORK_ERROR":
        return (
          <div className="text-center p-8">
            <h2 className="text-2xl font-bold text-red-600">네트워크 오류</h2>
            <p className="mt-2 text-gray-600">인터넷 연결을 확인해주세요.</p>
          </div>
        );
      default:
        return (
          <div className="text-center p-8">
            <h2 className="text-2xl font-bold text-red-600">
              알 수 없는 오류가 발생했습니다
            </h2>
            <p className="mt-2 text-gray-600">잠시 후 다시 시도해주세요.</p>
            <pre className="mt-4 p-4 bg-gray-100 rounded text-sm overflow-auto">
              {error?.message || "알 수 없는 오류"}
            </pre>
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="max-w-lg w-full bg-white rounded-lg shadow-lg">
        {renderErrorContent()}
        <div className="p-4 text-center">
          <button
            onClick={resetErrorBoundary}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            새로고침
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
