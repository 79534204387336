import App from "@/pages";
import { lazy } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const Login = lazy(() => import("@/pages/login/Login"));
const Layout = lazy(() => import("@/components/common/Layout"));
const SignUp = lazy(() => import("@/pages/signup/SignUp"));
const TimeLine = lazy(() => import("@/pages/timeline/TimeLineComponent"));
const Inspection = lazy(() => import("@/pages/inspection/Inspection"));
const Find = lazy(() => import("@/pages/login/find/Find"));
const ProtectedRoute = lazy(() => import("@/components/common/ProtectedRoute"));
const Password = lazy(() => import("@/pages/password/Password"));
const MyInfo = lazy(() => import("@/pages/myinfo/update/MyInfo"));
const NotFound = lazy(() => import("@/components/common/error/NotFound"));
const AdminLogin = lazy(() => import("@/pages/admin/login/AdminLogin"));
const AdminDashboard = lazy(
  () => import("@/pages/admin/dashboard/AdminDashboard")
);
const AdminRoute = lazy(() => import("@/components/admin/AdminRoute"));
const Introduce = lazy(() => import("@/pages/introduce/Introduce"));
const Guide = lazy(() => import("@/pages/guide/Guide"));
const Policy = lazy(() => import("@/pages/policy/Policy"));
const ExpireTokenPage = lazy(
  () => import("@/components/common/error/ExpireTokenPage")
);
const GuestPage = lazy(() => import("@/components/common/error/GuestPage"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <App /> },
      { path: "login", element: <Login /> },
      { path: "login/find", element: <Find /> },
      { path: "signup", element: <SignUp /> },
      { path: "signin/update/password", element: <Password /> },
      { path: "admin/login", element: <AdminLogin /> },
      { path: "introduce", element: <Introduce /> },
      { path: "guide", element: <Guide /> },
      { path: "policy", element: <Policy /> },
      { path: "error/guest", element: <GuestPage /> },
      { path: "error/token-expired", element: <ExpireTokenPage /> },
      {
        element: <ProtectedRoute />,
        children: [
          { path: "inspection", element: <Inspection /> },
          { path: "timeline/:inspectionId", element: <TimeLine /> },
          { path: "myinfo/update/", element: <MyInfo /> },
        ],
      },
      {
        element: <AdminRoute />,
        children: [{ path: "admin/dashboard", element: <AdminDashboard /> }],
      },
      { path: "*", element: <NotFound /> },
    ],
  },
]);

function BrowserRouterProvider() {
  return <RouterProvider router={router} />;
}

export default BrowserRouterProvider;
